<template>

	<!-- Active Users Card -->
	<a-card :bordered="false" class="dashboard-bar-chart">
		<chart-bar :height="220" :data="barChartData"></chart-bar>
		<div class="card-title">
			<h6>{{ datasetName }}</h6>
			<p>than last week <span class="text-success">{{percentageChange}}%</span></p>
		</div>
		<div class="card-content">
			<p>Here are some basic stats.</p>
		</div>
		<a-row class="card-footer" type="flex" justify="center" align="top">
			<a-col :span="12">
				<h4>{{ footerValue1 }}</h4>
				<span>{{ footerLabel1 }}</span>
			</a-col>
			<a-col :span="12">
				<h4>{{ footerValue2 }}</h4>
				<span>{{ footerLabel2 }}</span>
			</a-col>
			
		</a-row>
	</a-card>
	<!-- Active Users Card -->

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartBar from '../Charts/ChartBar' ;

	export default ({
		components: {
			ChartBar,
		},
		props:{
			dataLabels: Array,
			datasetName: String,
			data: Array,
			footerValue1: Number,
			footerLabel1: String,
			footerValue2: String,
			footerLabel2: String,
			percentageChange: Number,
		},
		mounted() {
			

			/*this.barChartData = {
					labels: this.dataLabels,
					datasets: [{
						label: this.datasetName,
						backgroundColor: '#fff',
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						data: this.data,
						maxBarThickness: 20,
					}, ],
				}


				console.log(this.barChartData);*/
		},
		data() {
			return {
				// Data for bar chart.
				barChartData: {
					labels: this.dataLabels,
					datasets: [{
						label: this.datasetName,
						backgroundColor: '#fff',
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 6,
						data: this.data,
						maxBarThickness: 20,
					}, ],
				}
			}
		},
	})

</script>